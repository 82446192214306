import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["motiveInput" ]

  submit(event) {
    this.motiveInputTarget.value = event.target.dataset.claimMotive
    this.element.submit()
  }
}
