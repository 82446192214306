function spendingForm(){
  if(document.querySelectorAll('.simple_form.new_spending')) {
    document.querySelectorAll('.simple_form.new_spending').forEach((spending_form) =>{

      document.getElementById(`submit_${spending_form.id}`).addEventListener('click', function(event){
        event.preventDefault();
        const formId = event.currentTarget.dataset.form
        const offerId = event.currentTarget.dataset.offer
        const form = document.getElementById(formId)

        const action = form.action;
        const method = form.method;

        const inputs = form.elements;
        const data = {};

        for (let i = 0; i < inputs.length; i++) {
          data[inputs[i].name] = inputs[i].value;
        }
        const spendingConsentConditionsAlert = document.getElementById(`spending_consent_conditions_alert_${offerId}`);

        // manual validation
        if (document.getElementById(`spending_consent_conditions_${offerId}`).checked){
          spendingConsentConditionsAlert.classList.add('hidden')
          // force redirection to vendor => offer is considered consumed by vendor
          // window.open(form.querySelector('input[name="spending[deep_link]"]').value, '_blank')
          // persist spending
          const csrfTokenMeta = document.querySelector("meta[name='csrf-token']")
          $.ajax({
            beforeSend: function(xhr) {
              xhr.setRequestHeader(
                'X-CSRF-Token',
                (csrfTokenMeta == null) ? null : csrfTokenMeta.content
              )
            },
            method: method,
            url: action,
            data: data,
            dataType: 'script',
            // success: function(){},
            error: function (responseObject) {
              alert(responseObject.responseText);
            }
          });
        } else {
          spendingConsentConditionsAlert.classList.remove('hidden')
        }

      });

    }) // forEach
  } // if

}
export { spendingForm }
