import Typed from 'typed.js';

function typedHome(){
  if(document.querySelector("#typed")){
    const typed = new Typed("#typed", {
      stringsElement: '#typed-strings',
      typeSpeed: 40,
      loop: true,
    });
  }
}
typedHome()
