import { Controller } from "stimulus"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

export default class extends Controller {

  static targets = [ "container", "img" ]

  show (e) {
    this.imgTarget.src = e.currentTarget.dataset.url
    this.containerTarget.classList.remove("hidden")
    disableBodyScroll(this.element)
  }

  hide () {
    this.containerTarget.classList.add("hidden")
    this.imgTarget.src = ""
    enableBodyScroll(this.element)
  }

  loaded () {
    requestAnimationFrame(() => {
      this.startRender()
    })
  }

  startRender () {
    requestAnimationFrame(() => {
      this.rendered()
    })
  }

  rendered () {
    this.centerImg()
  }

  centerImg () {
    let scrollX = (this.imgTarget.offsetWidth - this.containerTarget.offsetWidth) / 2
    let scrollY = (this.imgTarget.offsetHeight - this.containerTarget.offsetHeight) / 2
    this.containerTarget.scrollTo(scrollX, scrollY)
  }

}
