import swiperController from "./swiper_controller"

export default class extends swiperController {
  static targets = [ "main", "thumbs" ]

  connect () {
    setTimeout(() => {
      this.init()
    }, 500)

  }

  get container () {
    return this.mainTarget
  }

  get config () {
    let base = super.config

    base.thumbs = {
      swiper: this.thumbsTarget.swiper
    }

    return base
  }

}
