import Trix from "trix"

// Disable image underneath image attachments
Trix.config.attachments.preview.caption = {
  name: false,
  size: false
}

// Set custom attributes tied to class names on buttons defined in _toolbar.html.erb
function setCustomAttributes() {
  let attributes = Trix.config.blockAttributes
  attributes["heading1"] = {tagName: "h1"}
  attributes["heading2"] = {tagName: "h2"}
  attributes["heading3"] = {tagName: "h3"}
  Trix.config.blockAttributes = attributes
  // Text attributes
  // attributes = Trix.config.textAttributes
  // attributes["para_number"] = {tagName: "span"}
  // Trix.config.textAttributes = attributes
  // trixDefined()
}

window.onload = setCustomAttributes()


function setupTrixEditor(){
  if(document.getElementById('trix-anchor')){
    var trix = document.createElement('trix-editor');
    trix.setAttribute('input', 'post_content');

    // Toolbar can be overridden with toolbar attribute
    trix.setAttribute('toolbar', 'trix-toolbar');
    document.getElementById('trix-anchor').appendChild(trix);
  }
}

// Photo upload
(function() {
  var host, uploadAttachment;

  // Add and remove file event listeners
  document.addEventListener("trix-attachment-add", function(event) {
    var attachment;
    attachment = event.attachment;
    if (attachment.file) {
      uploadAttachment(attachment);
      // alert('Asset successfully added')
    }
  });

  document.addEventListener("trix-attachment-remove", function(event) {
    const attachment = event.attachment.attachment.attributes.values;
    removeAttachment(attachment);
    // alert('Asset successfully removed')
  });

  uploadAttachment = function(attachment) {
    var file, form, xhr;
    file = attachment.file;
    form = new FormData;
    form.append("Content-Type", file.type);
    form.append("photo[path]", file);  //Form element for pictures/_form
    xhr = new XMLHttpRequest;

    xhr.responseType = '';
    xhr.open("POST", '/post_assets', true);
    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));  //Add CSRF-Token into formData
    xhr.upload.onprogress = function(event) {
      var progress;
      progress = event.loaded / event.total * 100;
      return attachment.setUploadProgress(progress);
    };
    xhr.onload = function() {
      if (xhr.status === 201) {
        var data = JSON.parse(xhr.responseText);
        return attachment.setAttributes({
          url: data.url,
          href: data.url,
          photo_id: data.id
        });
      }
    };
    return xhr.send(form);
  };

  const removeAttachment = function(attachment) {
    return $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                  'photo_id': attachment.photo_id
                },
      type: 'DELETE',
      dataType: 'json',
      url: '/post_asset',
      cache: false,
      contentType: false,
      processData: false
    });
  };

}).call(this);
setupTrixEditor()
