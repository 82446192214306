import { Controller } from "stimulus"
import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl"

export default class extends Controller {
  static targets = ["container"]
  static values = { apiKey: String, address: Object, markers: Array, fullscreenButton: Boolean, ipCountry: String }

  connect() {
    this._override_mapbox_submit_on_enter()

    mapboxgl.accessToken = this.apiKeyValue

    const franceMapSettings  = { center: [1.8, 47.0], zoom: 3.6 }
    const belgiumMapSettings = { center: [4.3, 50.8], zoom: 6.2 }

    const mapSetting = (this.ipCountryValue == 'BE') ? belgiumMapSettings : franceMapSettings

    const center = this._hasAddress() ? this.addressValue : mapSetting.center
    const zoom = this._hasAddress() ? 13 : mapSetting.zoom

    this.map = new mapboxgl.Map({
      container: this.containerTarget,
      style: "mapbox://styles/resell/ckhqfbqv20x1o19kmog2pvd8o",
      center: center,
      zoom: zoom
    })

    this.markers = []
    this.addMapControls()
    this.addAddressMarker()
    this.addOtherMarkers()
    // this.fitMapToMarkers()
    this.initHighlight()

    if (window.innerWidth >= 1024 && this.hasFullscreenButtonValue && this.fullscreenButtonValue) this.createEnlargeButton()
  }

  _override_mapbox_submit_on_enter () {
    const adresseInput = document.querySelector('#collect-point-address-input')
    const addressForm = document.querySelector('#collect_points_form')
    if (adresseInput && addressForm) {
      adresseInput
        .addEventListener('keydown', function(event) {
          if (event.key === 'Enter') {
            addressForm.submit()
          }
        })
    }
  }

  addMapControls() {
    this.map.addControl(new mapboxgl.NavigationControl())
  }

  _hasAddress() {
    return this.hasAddressValue && Object.keys(this.addressValue).length !== 0
  }

  addAddressMarker() {
    if (!this._hasAddress()) return;

    this.markers.push(this.addressValue)

    const element = document.createElement("div")
    element.style.zIndex = 15
    element.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 20">
        <path d="M7 0C3.13 0 0 3.13 0 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5C5.62 9.5 4.5 8.38 4.5 7S5.62 4.5 7 4.5 9.5 5.62 9.5 7 8.38 9.5 7 9.5z">
        </path>
      </svg>
    `

    this._addMarker(element, this.addressValue)
  }

  addOtherMarkers() {
    if (!this.hasMarkersValue) return;
    if (this.markersValue.length < 1) return;

    this.markers = this.markers.concat(this.markersValue)

    this.markersValue.forEach((marker) => {
      const information = this._createInfoDiv(marker)
      const popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: true })
        .setHTML(information)

      const element = this._markerHTML(marker)
      this._addMarker(element, marker, popup)
    })
  }

  fitMapToMarkers() {
    if (this.markers.length < 1) return;
    const bounds = new mapboxgl.LngLatBounds()

    this.markers.forEach(marker => bounds.extend([marker.lng, marker.lat]))

    this.map.fitBounds(bounds, {
      padding: 70,
      maxZoom: 15,
      duration: 1000
    })
  }

  initHighlight() {
    const targets = document.querySelectorAll('[data-maptarget]');
    targets.forEach((target) => {
      target.addEventListener('click', this.highlight.bind(this));
      target.addEventListener('mouseenter', this.highlight.bind(this));
      target.addEventListener('mouseleave', this.resetHighlight);
    })
  }

  highlight(e) {
    const s = e.currentTarget.dataset.maptarget;
    if (s) {
      this.resetHighlight();
      const targets = document.querySelectorAll(`[data-maptarget="${s}"]`);
      targets.forEach((target) => { target.classList.add('active') });
    }
  }

  resetHighlight() {
    const targets = document.querySelectorAll('[data-maptarget].active');
    targets.forEach((target) => { target.classList.remove('active') });
  }

  createEnlargeButton() {
    const controlContainer = document.createElement('div')
    controlContainer.classList.add('mapboxgl-ctrl', 'mapboxgl-ctrl-group')

    this.resizeButton = document.createElement('button')
    this.resizeButton.classList.add('mapboxgl-ctrl-fullscreen')
    controlContainer.appendChild(this.resizeButton)

    const icon = document.createElement('span')
    icon.classList.add('mapboxgl-ctrl-icon')
    this.resizeButton.appendChild(icon)

    this.resizeButton.title = 'Plein écran'
    this.resizeButton.type = 'button'
    this.resizeButton.dataset.action = 'click->map#resize'

    this.element.querySelector('.mapboxgl-ctrl-top-right').appendChild(controlContainer)
  }

  resize() {
    this.resizeButton.classList.toggle("mapboxgl-ctrl-fullscreen")
    this.resizeButton.classList.toggle("mapboxgl-ctrl-shrink")
    this.element.classList.toggle('fullscreen')

    this.map.resize()
    this.fitMapToMarkers()
  }

  _addMarker(element, marker, popup = null) {
    const newMarker = new mapboxgl.Marker(element)
      .setLngLat([marker.lng, marker.lat])

    if (popup) {
      newMarker.setPopup(popup)

      element.addEventListener("click", (e) => {
        const lng = newMarker.getLngLat()['lng']
        const roomToCenterInfoWindow = 0.005
        const lat = newMarker.getLngLat()['lat'] - roomToCenterInfoWindow
        this.map.flyTo({
          center: { lng: lng, lat: lat},
          speed: 0.2
        })
      })
    }

    newMarker.addTo(this.map)
  }

  _createInfoDiv(marker) {

    const url = marker.url == null ? "" : `
      <a href="${marker.url}" target="_blank"\>
        <i class="fas fa-external-link-alt"></i>
      </a>
    `

    if (this._widgetParam) {
      const href =
        `${this._widgetParam}/users/sign_in?cpid=${marker.id}&${this._with_wauth}${this._with('wrus')}${this._with('ifr')}`
      return `
<div class='w-full px-2 pt-2'>
  <p class="text-xs text-black">
    <span class="font-weight-bold">${marker.name}${url}</span><br>
    ${marker.addressLine1}<br>
    ${marker.addressLine2 != '' ? marker.addressLine2 + '<br>' : ''}
    ${marker.addressLine3 != '' ? marker.addressLine3 + '<br>' : ''}
    ${marker.cityPostalCode}<br>
    ${marker.offer}<br>
    <pv style="max-width: 380px;">
      ${marker.hours}
    </p>
  </p>

  <div class="flex justify-center w-full">
    <a class="btn outline-none cta-custom-bg-color cta-custom-text-color mt-4 py-2 text-xs"
       rel="nofollow"
       data-cpid="${marker.id}"
       onclick="dataLayer.push({'event': 'new-donation-widget-trigger'})"
       href="${href}"
      >
      Sélectionner
    </a>
  </div>
</div>
      `
    }

    if (this._whiteLabelParam) {
      return `
<div class='w-full px-2 pt-2'>
  <p class="text-xs text-black">
    <span class="font-weight-bold">${marker.name}${url}</span><br>
    ${marker.addressLine1}<br>
    ${marker.addressLine2 != '' ? marker.addressLine2 + '<br>' : ''}
    ${marker.addressLine3 != '' ? marker.addressLine3 + '<br>' : ''}
    ${marker.cityPostalCode}<br>
    ${marker.offer}<br>
    <pv style="max-width: 380px;">
      ${marker.hours}
    </p>
  </p>

  <div class="flex justify-center w-full">
    <a class="btn outline-none cta-custom-bg-color cta-custom-text-color mt-4 py-2 text-xs"
       rel="nofollow"
       data-method="post"
       data-cpid="${marker.id}"
       onclick="dataLayer.push({'event': 'new-donation-widget-trigger'})"
       href="${this._whiteLabelParam}/donations/?cpid=${marker.id}&${this._with_wauth}"
      >
      Sélectionner
    </a>
  </div>
</div>
      `
    }

    // ${marker.addressLine2 != '' ? '<br>' + marker.addressLine2 : ''}
    return `
<div class='w-full px-2 sm:p-4'>
  <p class="font-mono text-md leading-tight font-bold mt-5 sm:mt-2 mb-2">${marker.type_name} — ${marker.name}${url}</p>
  <p class='font-mono text-xs leading-tight'>
    ${marker.addressLine1}<br>
    ${marker.addressLine2 != '' ? marker.addressLine2 + '<br>' : ''}
    ${marker.addressLine3 != '' ? marker.addressLine3 + '<br>' : ''}
    ${marker.cityPostalCode}<br>
    <pv style="max-width: 380px;">
      ${marker.hours}
    </p>
  </p>
  <div class='text-secondary'>${marker.offer}</div>
  <div class="flex justify-center">
    <a class="btn outline-none mt-4 py-2 text-xs"
      rel="nofollow"
      data-method="post"
      data-cpid="${marker.id}"
      onclick="dataLayer.push({'event': 'new-donation-main-app-trigger'})"
      href="/donations/?cpid=${marker.id}"
      >
      Sélectionner
    </a>
  </div>
</div>
    `
  }

  _markerHTML(marker) {
    const element = document.createElement("div")
    element.classList.add("marker")
    element.innerText = marker.i
    element.classList.add("pinpoint")

    if (this._widgetParam && marker.belongsToHost) {
      element.classList.add("cta-custom-color")
      element.style.zIndex = 1
    } else if (this._widgetParam) {
      element.classList.add("active")
    } else {
      element.dataset.maptarget = `m-${marker.i}`
    }

    return element
  }

  get _widgetParam() {
    const regexp = window.location.href.match(/\/w\/.{36}/);

    return regexp ? regexp[0] : "";
  }

  get _whiteLabelParam() {
    const regexp = window.location.href.match(/\/wl\/.{36}/);

    return regexp ? regexp[0] : "";
  }

  get _with_wauth() {
    if (window.location.href.match(/wuid=[0-9]+_[0-9]+/)) {
      return window.location.href.match(/wuid=[0-9]+_[0-9]+/)[0]
    }

    return ""
  }

  _with(key) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const value = urlParams.get(key)
    if (value) {
      return `&${key}=${value}`
    }

    return ''
  }
}
