import {toggleSidebar} from "./toggle-sidebar";

const navbar = () =>
  document.getElementById("sidebar")

// TODO REDESIGN update
function closeNavbar() {
  window.addEventListener("keydown", function (event) {
    if (navbar() && event.key == "Escape" || event.key == "Esc") {
      if (navbar().classList.contains('passive')){
        toggleSidebar()
      }
    }
  }, true);
}

export { closeNavbar }
