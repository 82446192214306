import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cartContent", "cartIcon", "cartIconCount", "total"]

  connect() {
    this.mouseCaptured = false
  }

  updateIcon() {
    if (this.hasTotalTarget) {
      this.cartIconCountTarget.innerText = this.totalTarget.dataset.itemCount
    } else {
      this.cartIconTarget.style.display = "none"
      this.cartContentTarget.classList.add("hidden")
    }
  }

  show() {
    if (!this.hasCartContentTarget) return

    this.cartContentTarget.classList.remove("hidden")
  }

  // We don't want the cart to hide if the mouse left very temporarily the frame
  hide() {
    setTimeout(this.hideNow.bind(this), 500)
  }

  hideNow() {
    if (!this.hasCartContentTarget) return
    if (this.mouseCaptured) return

    this.cartContentTarget.classList.add("hidden")
  }

  captureMouse() {
    this.mouseCaptured = true
  }

  freeMouse() {
    this.mouseCaptured = false
    this.hide()
  }
}
