import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css" // Note this is important!
import { French } from "flatpickr/dist/l10n/fr.js"

flatpickr(".datepicker", {
  locale: French
})
flatpickr(".datetimepicker", {
  locale: French,
  enableTime: true,
  time_24hr: true
})

flatpickr(".datepickerMinimumDefaultToday", {
  locale: French,
  minDate: new Date(),
  defaultDate: new Date()
})
