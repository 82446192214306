import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['chart', 'kpiButton', 'timeframeButton']
  static values = {url: String, timeframe: String, kpi: String}

  connect() {
    google.charts.load('current', {'packages':['corechart']})
    google.charts.setOnLoadCallback(this._drawChart.bind(this))
  }
  
  changeChart(event) {
    if (event.target.dataset.vendorKpisTarget === 'kpiButton') {
      this._updateKpi(event.target.dataset.value)
    } else {
      this._updateTimeframe(event.target.dataset.value)
    }

  }

  redrawChart() {
    if (!this.chart) return

    this._draw()
  }

  generateCSV() {
    const now = (new Date()).toISOString().slice(0, 10)

    const downloadLink = document.createElement("a")
    downloadLink.href = `${this.urlValue}.csv?timeframe=${this.timeframeValue}`
    downloadLink.download = `${now} - ${this.kpiValue}s (${this.timeframeValue}).csv`
  
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  _updateKpi(value) {
    if (value === this.kpiValue) return

    this.urlValue = this.urlValue.replace(this.kpiValue, value)
    this.kpiValue = value
    this._drawChart()
  }

  _updateTimeframe(value) {
    if (value === this.timeframeValue) return

    this.timeframeValue = value
    this._drawChart()
  }

  async _drawChart() {
    const data = await this._getData()

    this._setActiveButton()
    
    if (data.length === 1) {
      this.chart = null
      this.chartTarget.innerHTML = 'Pas de donnée disponible pour le moment.'
      return
    }

    this.dataTable = google.visualization.arrayToDataTable(data)

    const date_formatter = new google.visualization.DateFormat({ 
        pattern: "YYYY-MM-dd"
    })
    
    date_formatter.format(this.dataTable, 0)

    this._draw()
  }

  _draw() {
    const oneDay = (1000 * 60 * 60 * 24)
    let hAxisTicks = []
    let dateRange = this.dataTable.getColumnRange(0)

    for (var i = dateRange.min.getTime(); i <= dateRange.max.getTime(); i = i + oneDay) {
      hAxisTicks.push(new Date(i))
    }

    const options = {
      hAxis: {
        format: 'YYYY-MM-dd',
        slantedText: true,
        slantedTextAngle: 45,
        ticks: hAxisTicks
      },
      vAxis: {
        ticks: this._getvAxisTicks(this.dataTable.getColumnRange(1))
      },
      seriesType: 'line',
      // seriesType: 'bars',
      // series: { 1: { type: 'line' } },
      legend: { position: 'bottom' }
    }

    this.chart ||= new google.visualization.ComboChart(this.chartTarget)
    this.chart.draw(this.dataTable, options)
  }

  async _getData() {
    const url = `${this.urlValue}?timeframe=${this.timeframeValue}`
    const token = document.querySelector("[name='csrf-token']").content

    const rawData = await fetch(url, {
      headers: {
        "X-CSRF-Token": token,
        'Content-Type': 'application/json'
      }
    })

    const rawJson = await rawData.json()
    return rawJson.map((row, index) => {
      if (index === 0) return row

      row[0] = new Date(row[0])

      row.splice(1, 1) // remove this if you need to show the bars

      return row
    })
  }

  _setActiveButton() {
    this.kpiButtonTargets.forEach(button => this._colorButton(button, this.kpiValue))
    this.timeframeButtonTargets.forEach(button => this._colorButton(button, this.timeframeValue))

  }

  _colorButton(button, activeValue) {
    button.classList.toggle('bg-black', button.dataset.value === activeValue)
    button.classList.toggle('text-white', button.dataset.value === activeValue)
  }

  _getvAxisTicks(range){
    const step = Math.ceil(range.max / 5)
    const ticks = Array.from(Array(6).keys()) // [0, 1, 2, 3, 4, 5, 6]
    return ticks.map(n => n * step)
  }
}
