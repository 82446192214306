
const closeAllQuestions = function(){
  document.querySelectorAll('.answer').forEach((answer) =>{ answer.classList.remove('is-visible')})
}

const closeAllQuestionGroups = function(){
  document.querySelectorAll('.questions').forEach((group) =>{ group.classList.remove('is-visible')})
}

const collapseQuestionGroups = function(){
  document.querySelectorAll('.question-arrow').forEach((question_group) => {
    question_group.addEventListener('click', function(){
      if (event.currentTarget.nextElementSibling.classList.contains("is-visible")){
        closeAllQuestionGroups()
      } else {
        closeAllQuestionGroups()
        event.currentTarget.nextElementSibling.classList.add("is-visible")
      }
      event.currentTarget.querySelector('img').classList.toggle("rotate-90deg")
    })
  })
}

const collapseIndividualQuestions = function(){
  document.querySelectorAll('.question').forEach((question) =>{
    question.addEventListener('click', function(){
      if (question.nextElementSibling.classList.contains('answer')){
        if (question.nextElementSibling.classList.contains('is-visible')){
          closeAllQuestions()
        } else {
          closeAllQuestions()
          question.nextElementSibling.classList.add('is-visible')
        }
      }
    })
  })
}

const faqAccordeon = function(){
  closeAllQuestionGroups()
  closeAllQuestions()
  collapseQuestionGroups()
  collapseIndividualQuestions()
}

export { faqAccordeon }


