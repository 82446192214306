import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["partial"]

  submit() {
    Rails.fire(this.element, "submit")
  }

  render(e) {
    document.body.innerHTML = e.detail[0].body.innerHTML
  }

  renderInnerPartial(e) {
    if (!this.hasPartialTarget) return

    this.partialTarget.innerHTML = e.detail[0].querySelector("[data-form-target='partial']")?.innerHTML
  }
}