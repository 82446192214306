import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"];

  toggle(event){
    this.inputTargets.forEach(label => { label.classList.remove(this.toggleKlass) })
    event.currentTarget.classList.add(this.toggleKlass)
  }

  get toggleKlass() {
    return this.data.get('active')
  }
}
