import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { debounce } from "debounce"

export default class extends Controller {
  static targets = [
    'newPreviewGnal',
    'newPreview',
    'showPreview',
    'qrCodes',
    'squareLogoInput',
    'backgroundLanscapeInput',
    'wCustomFontLink'
  ]

  static values = { vendorId: Number }

  connect() {
    this.inputFields = []
  }

  updateWidget(e) {
    const field = e.target.name
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value
    this.inputFields.push([field, value])

    this._fetch(e.target)
  }

  debouncedUpdateWidget(e) {
    if (this.debouncedUpdate) this.debouncedUpdate.clear()

    this.debouncedUpdate = debounce(this.updateWidget.bind(this), 1250)
    this.debouncedUpdate(e)
  }

  _fetch(element = null) {
    const whitelabelData = new FormData()

    this.inputFields.forEach((input) => {
      const field = input[0]
      const value = input[1]

      whitelabelData.append(field, value)
    })
    this.inputFields = []

    Rails.ajax({
      type: "patch",
      url: this.element.action,
      data: whitelabelData,
      success: (data) => { this._updateView(data, element) },
      error: (data) => { console.log("Error - ", data) }
    })
  }

  _updateView(html, element) {
    this.newPreviewGnalTarget.innerHTML =
      html.querySelector('[data-instore-donations-customization-target="newPreviewGnal"]').innerHTML
    this.newPreviewTarget.innerHTML =
      html.querySelector('[data-instore-donations-customization-target="newPreview"]').innerHTML
    this.showPreviewTarget.innerHTML =
      html.querySelector('[data-instore-donations-customization-target="showPreview"]').innerHTML
    this.qrCodesTarget.innerHTML =
      html.querySelector('[data-instore-donations-customization-target="qrCodes"]').innerHTML

    if (element?.type === "file") location.reload()
  }
}
