import moment from "moment";
import {
  userSessionExists,
  userWasInvited
} from "../components/user-state-utils";

const popup = () =>
  document.querySelector("#WelcomeMessagePopup")

const welcomeMessagePopupSeen =() =>
  document.querySelector("#welcome_message_popup").dataset.seen == "true"

const inWidget = () =>
  document.querySelector("#w-layout")

const fillingProfileForm = () =>
  document.querySelector("#profile_form")

const show = () => {
  popup().classList.remove("hidden");
}

const closeOnEscape = () => {
  document.addEventListener("keyup", function(e) {
    if (popup() && e.which == 27 && !popup().classList.contains("hidden")) {
      popup().classList.add("hidden")
    }
  });
}

const setupWelcomeMessagePopup = () => {
  show()
  closeOnEscape()
}

const welcomeMessagePopup = () => {
  document.addEventListener("DOMContentLoaded", function(event) {
    const widget = document.getElementById("w-layout")

    const temporarilyBlocked = true
    // TODO: restore or permanently remove (2024?)

    if (
      !widget &&
      userSessionExists() &&
      !welcomeMessagePopupSeen() &&
      !userWasInvited() &&
      !inWidget() &&
      !fillingProfileForm() &&
      !temporarilyBlocked
    ) {
      setTimeout(setupWelcomeMessagePopup, 5000);
    }
  });
}

export { welcomeMessagePopup };
