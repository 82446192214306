import { Controller } from "stimulus"
import moment from 'moment';

export default class extends Controller {
  static targets = ['cta']

  connect(){
    // if ( this.ctaTarget && !this.confirmationCookiePresent() ) {
    //   setTimeout(this.reveal_or_notifyUser.bind(this), 2000);
    // }
  }

  reveal_or_notifyUser(){
    if (!this.ctaTarget.hasAttribute('data-extension-installed')) {
      this.revealCta()
      const userConfirmed = confirm("Télécharger l'extension Redonner pour bénéficiez de récompenses sur le site de nos marques partenaires !")
      if (userConfirmed) {
        this.setConfirmationCookie()
      }
    }
  }

  revealCta(){
    this.ctaTarget.classList.add('d-block')
    this.ctaTarget.classList.add('shake')
    this.ctaTarget.classList.remove('d-none')
  }

  confirmationCookiePresent(){
    const existing_cookie = document.cookie.split(';').filter((item) => item.trim().startsWith('redonner-chrome-extension-notified='))
    return (existing_cookie.length ? true : false)
  }

  setConfirmationCookie(){
    const thirtyDaysFromNow = moment().add(30, 'days').utc().toISOString();
    const cookie = `redonner-chrome-extension-notified=true;expires=${thirtyDaysFromNow}`
    document.cookie = cookie
  }
}
