import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { debounce } from "debounce"
import { addressAutocomplete } from '../components/address_autocomplete.js';

export default class extends Controller {
  static targets = [
    "buttonDetails", "widgetPreview", "widgetHomepagePreview",
    "wCustomFontLink",
    "widgetDonationPreview", "widgetHowToPreview", "backgroundInput", "backgroundDesktopInput",
    "button", "landingPreviewValidations", "landingPreviewValidationsSuccess",
    "backgroundPreview", "textOpenButtonInput", "textOpenButtonInputMobile",
    "customImageOpenButtonInput", "customImageCloseButtonInput",
    "customImageOpenButtonInputMobile",
    "lineHeightRange", "lineHeightValue"
  ]

  static values = { vendorId: Number }

  connect() {
    this.inputFields = []
  }

  updateWidget(e) {
    const field = e.target.name
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value
    this.inputFields.push([field, value])

    this._fetch(e.target)
  }

  debouncedUpdateWidget(e) {
    if (this.debouncedUpdate) this.debouncedUpdate.clear()

    this.debouncedUpdate = debounce(this.updateWidget.bind(this), 1250)
    this.debouncedUpdate(e)
  }


  defaultOpenButton() {
    const field1 = "vendor[w_btn_text]"
    const value1 = ""
    this.inputFields.push([field1, value1])

    const field2 = "vendor[remove_w_open_icon]"
    const value2 = "1"
    this.inputFields.push([field2, value2])

    this._fetch()
  }

  defaultOpenButtonMobile() {
    const field1 = "vendor[w_btn_text_mobile]"
    const value1 = ""
    this.inputFields.push([field1, value1])

    const field2 = "vendor[remove_w_open_icon_mobile]"
    const value2 = "1"
    this.inputFields.push([field2, value2])

    this._fetch()
  }

  textOpenButton() {
    this.textOpenButtonInputTarget.classList.remove("hidden")
    this.textOpenButtonInputTarget.focus()

    const field = "vendor[remove_w_open_icon]"
    const value = "1"
    this.inputFields.push([field, value])
  }

  textOpenButtonMobile() {
    this.textOpenButtonInputMobileTarget.classList.remove("hidden")
    this.textOpenButtonInputMobileTarget.focus()

    const field = "vendor[remove_w_open_icon_mobile]"
    const value = "1"
    this.inputFields.push([field, value])
  }


  toggleCheckbox(e) {
    const field = e.currentTarget
    this.inputFields.push([field.name, field.checked])
    const spinner = document.querySelector(`#spinner_${field.id}`)
    spinner.classList.remove('hidden')
    this._fetch()
  }

  updateLineHeight(e) {
    this.lineHeightValueTarget.innerText = this.lineHeightRangeTarget.value
    if (this.debouncedRangeUpdate) this.debouncedRangeUpdate.clear()

    this.debouncedRangeUpdate = debounce(this.updateWidget.bind(this), 1500)
    this.debouncedRangeUpdate(e)
  }

  loading(e) {
    e.currentTarget.classList.add('animate-pulse')
  }

  customImageOpenButton() {
    this.textOpenButtonInputTarget.classList.add("hidden")
    this.textOpenButtonInputTarget.value = ""
    this.customImageOpenButtonInputTarget.click()

    const field = "vendor[w_btn_text]"
    const value = ""
    this.inputFields.push([field, value])
    this._fetch()
  }

  customImageOpenButtonMobile() {
    this.textOpenButtonInputMobileTarget.classList.add("hidden")
    this.textOpenButtonInputMobileTarget.value = ""
    this.customImageOpenButtonInputMobileTarget.click()

    const field = "vendor[w_btn_text_mobile]"
    const value = ""
    this.inputFields.push([field, value])
    this._fetch()
  }

  // Functionality deprecated by Stakeholders — Keeping the logic for now
  // defaultCloseButton() {
  //   const field = "vendor[remove_w_close_icon]"
  //   const value = "1"
  //   this.inputFields.push([field, value])

  //   this._fetch()
  // }

  // customImageCloseButton() {
  //   this.customImageCloseButtonInputTarget.click()
  // }

  updatePixelInput(e) {
    if (this.debouncedPixelUpdate) this.debouncedPixelUpdate.clear()

    this.debouncedPixelUpdate = debounce(this.updateWidget.bind(this), 850)
    this.debouncedPixelUpdate(e)
    this.buttonTarget.style.bottom = `${e.target.value}px`
  }

  _fetch(element = null) {
    const widgetData = new FormData()

    this.inputFields.forEach((input) => {
      const field = input[0]
      const value = input[1]

      widgetData.append(field, value)
    })
    this.inputFields = []

    Rails.ajax({
      type: "patch",
      url: this.element.action,
      data: widgetData,
      success: (data) => { this._updateView(data, element) },
      error: (data) => { console.log("Error - ", data) }
    })
  }

  _updateView(html, element) {
    document.querySelectorAll('svg.animate-spin').forEach((spinner) => spinner.classList.add('hidden'))
    this.buttonDetailsTarget.innerHTML = html.querySelector('[data-widget-customization-target="buttonDetails"]').innerHTML
    this.widgetPreviewTarget.innerHTML = html.querySelector('[data-widget-customization-target="widgetPreview"]').innerHTML
    this.widgetHomepagePreviewTarget.innerHTML = html.querySelector('[data-widget-customization-target="widgetHomepagePreview"]').innerHTML
    this.widgetDonationPreviewTarget.innerHTML = html.querySelector('[data-widget-customization-target="widgetDonationPreview"]').innerHTML
    this.widgetHowToPreviewTarget.innerHTML = html.querySelector('[data-widget-customization-target="widgetHowToPreview"]').innerHTML
    this.landingPreviewValidationsTarget.innerHTML = html.querySelector('[data-widget-customization-target="landingPreviewValidations"]').innerHTML
    this.landingPreviewValidationsSuccessTarget.innerHTML = html.querySelector('[data-widget-customization-target="landingPreviewValidationsSuccess"]').innerHTML
    this.wCustomFontLinkTarget.value = html.querySelector('[data-widget-customization-target="wCustomFontLink"]').value
    // Needed to keep Algolia CSS on the Donation preview after AJAX reload
    addressAutocomplete()

    if (element?.type === "file") this._updateImages(element)
  }

  _updateImages(element) {
    if (element.name !== "vendor[w_bg_photo]") return location.reload()

    const image = URL.createObjectURL(element.files[0])

    this.backgroundInputTarget.src = image
    this.backgroundPreviewTargets.forEach((background) => {
      background.style.backgroundImage = `url("${image}")`
    })
  }
}
