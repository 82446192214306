import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['collectPoint']

  createCookie(){
      document.cookie = `collect_point_id=${this.collectPointId}`;
  }

  get collectPointId() {
    return this.collectPointTarget.dataset.collectPointId
  }
}

// deprecated?
