import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'form',
    'filterWrapper',
    'visible',
    'input',
    'categoryIds',
    'vendorIds',
    'menuIds',
    'offer'
  ]

  slideIn() {
    this.visibleTarget.value = "true"
    this.filterWrapperTarget.classList.add('offer-filter-slide-in')
  }

  slideOut(e) {
    e.preventDefault()
    this.visibleTarget.value = "false"
    this.filterWrapperTarget.classList.remove('offer-filter-slide-in')
  }

  update() {
    this.offerTargets.forEach((offer) => offer.classList.add("opacity-50"))
    Rails.fire(this.formTarget, 'submit')
  }

  render(e) {
    window.history.pushState('', '', e.detail[2].responseURL)
    this.offerTargets.forEach((offer) => offer.classList.remove("opacity-50"))
    const newBody = document.createElement("div")
    newBody.innerHTML = e.detail[2].response
    this.element.innerHTML = newBody.innerHTML
    this.visibleTarget.value = "true" ? this.slideIn() : this.slideOut()
  }

  updateCategories(e) {
    e.preventDefault()
    const id = e.currentTarget.dataset.id
    const inputValue = this.categoryIdsTarget.value
    if (this.categoryIdsTarget.value.includes(id)) {
      this.categoryIdsTarget.value = inputValue.replace(`_${id}`, '')
    } else {
      this.categoryIdsTarget.value = inputValue.concat(`_${id}`)
    }

    this.update()
  }

  updateVendors(e) {
    e.preventDefault()
    const id = e.currentTarget.dataset.id
    const inputValue = this.vendorIdsTarget.value
    if (this.vendorIdsTarget.value.includes(id)) {
      this.vendorIdsTarget.value = inputValue.replace(`_${id}`, '')
    } else {
      this.vendorIdsTarget.value = inputValue.concat(`_${id}`)
    }

    this.update()
  }

  updateMenus(e) {
    e.preventDefault()
    const id = e.currentTarget.dataset.id
    const inputValue = this.menuIdsTarget.value
    if (this.menuIdsTarget.value.includes(id)) {
      this.menuIdsTarget.value = inputValue.replace(`_${id}`, '')
    } else {
      this.menuIdsTarget.value = inputValue.concat(`_${id}`)
    }
    this.update()
  }
}
