import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]
  static values = { category: String }

  expand() {
    this._fetch(`?${this.categoryValue}=true`)
  }

  shrink() {
    this._fetch()
  }

  _fetch(fetch_params = "") {
    fetch(`${window.location.href}${fetch_params}`)
      .then(data => data.text())
      .then((html) => {
        const newBody = document.createElement("div")
        newBody.innerHTML = html
        const content = newBody.querySelector(`[data-dashboard-plus-category-value="${this.categoryValue}"]`)

        this.containerTarget.innerHTML = content.innerHTML
      })
  }
}
