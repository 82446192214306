import moment from "moment";
import {
  userSessionExists
} from "../components/user-state-utils";

const existing_cookie = () =>
  document.cookie.split(";")
   .filter(item => item.trim().startsWith("register-popup-closed="));

const popupClosedCookieExists = () =>
  existing_cookie().length ? true : false;

const oneDayFromNow = () => moment().add(1, "days").utc().toISOString();

const cookie = () => `register-popup-closed=true;expires=${oneDayFromNow()}`

const setCookie = () => { document.cookie = cookie() }

const show = () => { popup().classList.remove("hidden") }

const hide = () => { popup().classList.add("hidden") }

const popup = () => document.querySelector("#RegisterPopup")

const registerCloseBtn = () => document.querySelector("#RegisterPopupCloseBtn")

const registerPopup =() => {
  document.addEventListener("DOMContentLoaded", function() {
    if (
      !userSessionExists() &&
      !popupClosedCookieExists()
    ) {
      if (popup()) {
        setTimeout(() => {
          show()
          document.querySelector("#user_email").focus()
        }, 500)
        registerCloseBtn().addEventListener("click", function(){
          setCookie();
          hide();
        });
      }
    }
  });
}

export { registerPopup };