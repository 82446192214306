function displayFieldAsInvalid(emailField){
  emailField.classList.add("invalid");
}

function displayFieldAsValid(emailField){
  emailField.classList.remove("invalid");
}

function deactivateBtn(){
  const btn = document.getElementById('recommander-btn')
  btn.classList.add("disabled");
  btn.disabled = true;
}

function activateBtn(){
  const btn = document.getElementById('recommander-btn')
  btn.classList.remove("disabled");
  btn.disabled = false;
}

function recommendationFormListener(){
  if (document.querySelectorAll('.recommendation-email').length  != 0) {
    let emailFields = document.querySelectorAll('.recommendation-email')
    let emailFieldsCount = emailFields.length
    let lastEmailField = emailFields[emailFieldsCount - 1]
    let fired = false
    lastEmailField.addEventListener('input', (event) => {
      const initial_value = ''
      if(initial_value != event.currentTarget.value && !fired) {
        fired = true
        addNewEmailField(emailFieldsCount);
      }
    });

    emailFields.forEach((emailField) => {
      emailField.addEventListener('input', (event) => { validateEmails(emailFields) });
    })
  }
}

function addNewEmailField(emailFieldsCount){
  const emailsWrapper = document.querySelector(".recommendation-emails");
  let emailFieldId = emailFieldsCount + 1
  let emailField = `
    <div class="form-group string optional">\
      <input class="form-control string optional recommendation-email" type="text" name="search[email${emailFieldId}]" id="search_email${emailFieldId}">\
    </div>`
  emailsWrapper.insertAdjacentHTML('beforeend', emailField);
  recommendationFormListener()
}

function validateEmail(emailField){
  const emailRegex = /[a-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})/
  return (emailRegex.test(emailField.value) || emailField.value == '')
}

function validateEmails(emailFields){

  let anyFails = false
  emailFields.forEach((emailField) => {
    if (!validateEmail(emailField)){
      displayFieldAsInvalid(emailField);
      anyFails = true;
    } else {
      displayFieldAsValid(emailField);
    }
  })
  anyFails ? deactivateBtn() : activateBtn();
}

export { recommendationFormListener };
