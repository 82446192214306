function accountCheckBoxAnchor(){
  const inputAndLabel = document.querySelectorAll('.idle_newsletter_consent')
  if(inputAndLabel.length > 0){
    inputAndLabel.forEach((inputOrLabel) => {
    inputOrLabel.addEventListener('click',(e)=>{
      e.preventDefault()
      window.location ='/compte/profil/edit'
    })
  })
  }
}

export { accountCheckBoxAnchor }
