import { Controller } from "stimulus"

export default class extends Controller {

  connect () {
    const observer = new IntersectionObserver(([e]) => {
      e.target.classList.toggle("is-sticky", e.intersectionRatio < 1)
    }, {
      threshold: [1]
    })

    observer.observe(this.element)
  }

}