import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['coordinates', 'manuallyGeocoded']

  connect(){
    if (this.manuallyGeocoded()) {
      this.showCoordinates()
    }
  }

  toggleCoordinates(){
    this.coordinatesTargets.forEach((coord) => {
      coord.classList.toggle('d-none')
    })
  }

  showCoordinates(){
    this.coordinatesTargets.forEach((coord) => coord.classList.remove('d-none'))
  }

  manuallyGeocoded(){
    return this.manuallyGeocodedTarget.checked
  }
}
