import { Controller } from "stimulus"

export default class extends Controller {
  static values = { title: String }
  static targets = [ "tooltipFrame" ]

  show(e) {
    if (!this.hasTooltipFrameTarget) this.create()

    this.tooltipFrameTarget.classList.remove("hidden")
  }

  hide() {
    if (!this.hasTooltipFrameTarget) return

    this.tooltipFrameTarget.classList.add("hidden")
  }

  create() {
    const html = `
      <div class="relative" data-tooltip-target="tooltipFrame">
        <div class="absolute bottom-8 left-0 z-100 bg-white -left-10 shadow-lg p-2 min-w-max tooltip">
          ${ this.titleValue }
        </div>
      </div>
    `

    const tooltip = document.createRange()
                            .createContextualFragment(html)

    this.element.appendChild(tooltip)
  }
}
