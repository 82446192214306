import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['item']

  connect() {
    if (this.hasItemTarget) {
      setTimeout(this.fade.bind(this), 3000);
    }
  }

  fade() {
    this.itemTarget.classList.add("opacity-0")
  }
}
