import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "drawer", "backdrop", "btnclose", "btnopen", "item"]

  connect() {
    this.alignDrawerItemsToBtn()
    window.addEventListener('resize', this.alignDrawerItemsToBtn.bind(this))
  }

  alignDrawerItemsToBtn () {
    const btnOpenOffsetX = this.btnopenTarget.getBoundingClientRect().x
    const btnOCloseOffsetX = this.btncloseTarget.getBoundingClientRect().x
    const offsetsX = btnOpenOffsetX + btnOCloseOffsetX
    this.itemTargets.forEach(item => item.style.marginLeft = `${offsetsX + 18}px`)
  }

  toggle (e) {
    e.stopPropagation()
    if (this.drawerTarget.classList.contains("-translate-x-96")) {
      this.open()
    }
    else {
      this.close()
    }
  }

  open () {
    this.drawerTarget.classList.remove("-translate-x-96")
    this.drawerTarget.classList.remove("w-96")
    this.backdropTarget.classList.add("inset-0")
    this.btnopenTarget.classList.add("hidden")
    this.btncloseTarget.classList.remove("hidden")
  }

  close () {
    this.drawerTarget.classList.add("-translate-x-96")
    this.drawerTarget.classList.add("w-96")
    this.backdropTarget.classList.remove("inset-0")
    this.btnopenTarget.classList.remove("hidden")
    this.btncloseTarget.classList.add("hidden")
  }
}
