import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  changeTab(event) {
    this.selectActiveTab(event.currentTarget)
    this.selectActivePanel(event.currentTarget.dataset.tabIndex)
  }

  selectActiveTab(currentTab) {
    this.tabTargets.forEach(tab => { tab.classList.remove('active') })
    currentTab.classList.add('active')
  }

  selectActivePanel(panelID) {
    this.panelTargets.forEach(panel => {
      if (panel.dataset.tabIndex == panelID) {
        panel.removeAttribute("hidden")
        panel.classList.remove("hidden")
      } else {
        panel.setAttribute("hidden", "true")
        panel.classList.add("hidden")
      }
    });
  }
}
