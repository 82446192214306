import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal" ]

  open (e) {
    e.preventDefault()
    this.modalTarget.classList.remove("hidden")
  }

  close () {
    this.modalTarget.classList.add("hidden")
  }
}
