import { Controller } from "stimulus"
import Rails from '@rails/ujs'

const style = {
  base: {
    color: '#32325d',
    fontFamily: "'Aeroport Mono', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', 'monospace'",
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};


export default class extends Controller {
  static targets = [ 'form', 'submitButton' ]

  connect () {
    this.submitButtonTarget.disabled = true

    fetch(this.paymentIntentsCreatePath, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ order_id: this.orderId })
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        this.build(data)
      });
  }

  build (data) {
    this.stripe = Stripe(this.publishableKey, {
      locale: this.data.get("locale")
    })

    this.card = this.stripe.elements().create("card", { style: style });

    this.card.mount("#card-element");

    this.card.addEventListener("change", (e) => {
      this.submitButtonTarget.disabled = e.empty
      document.querySelector("#card-error").textContent = e.error ? e.error.message : ""
    });

    this.submitButtonTarget.addEventListener("click", (e) => {
      e.preventDefault();
      this.disableButton()
      this.submitPaying(data.clientSecret);
    });
  }

  submitPaying (clientSecret) {
    this.stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card
        }
      })
      .then((result) => {
        if (result.error) {
          this.showError(result.error.message);
        } else {
          this.orderComplete(result.paymentIntent.id);
        }
      });
  };

  orderComplete (paymentIntentId) {
    // Rails-ujs only submitting ajax !
    // Rails.fire(this.formTarget, "submit")

    this.formTarget.submit()
    // TODO: polling on order.type
    // window.location.pathname == order_path if paid?
    // stripe_hooks listening to payment_intent.created
  };

  showError (errorMsgText) {
    this.enableButton()
    const errorMsg = document.querySelector("#card-error")
    errorMsg.textContent = errorMsgText
    setTimeout(function() {
      errorMsg.textContent = "";
    }, 7000);
  };

  disableButton = () => {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('bg-gray-200')
    this.submitButtonTarget.value = 'CHARGEMENT'
  }

  enableButton = () => {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('bg-gray-200')
    this.submitButtonTarget.value = 'PAYER MAINTENANT'
  }

  get orderId () {
    return this.data.get("orderId")
  }

  get publishableKey () {
    return this.data.get("publishableKey")
  }

  get paymentIntentsCreatePath () {
    return this.data.get("paymentIntentsCreatePath")
  }

}
