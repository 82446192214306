import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['form', 'filterWrapper', 'visible', 'input', 'categoryId', 'sorting']

  slideIn () {
    this.visibleTarget.value = "true"
    this.filterWrapperTarget.classList.add('product-filter-slide-in')
  }

  slideOut (e) {
    e.preventDefault()
    this.visibleTarget.value = "false"
    this.filterWrapperTarget.classList.remove('product-filter-slide-in')
  }

  update () {
    this.element.classList.add("product-filter-loading")
    Rails.fire(this.formTarget, 'submit')
  }

  render (e) {
    window.history.pushState('', '', e.detail[2].responseURL)
    this.element.classList.remove("product-filter-loading")
    this.element.innerHTML = e.detail[2].response
  }

  updateCategory(e) {
    e.preventDefault()
    this.categoryIdTarget.value = e.currentTarget.dataset.id

    this.update()
  }

  updateSorting(e) {
    this.sortingTargets.forEach(el => {
      if (e.currentTarget !== el) {
        el.checked = false
      }
    })

    this.update()
  }

  syncInputs(e) {
    const target = e.currentTarget
    this.inputTargets.forEach(el => {
      if (el.name === target.name) {
        el.checked = target.checked
      }
    })
  }

}
