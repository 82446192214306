require.context('images', true);
require.context('icons', true);
import 'application.css';
import Rails from '@rails/ujs';

import "bootstrap";
import '../plugins/clipboard';
import "../plugins/flatpickr"
import '../plugins/typed';
import '../plugins/tippy-tooltips';
import '../plugins/trix';
import '../plugins/instascanner';

import {toggleSidebar} from "../components/toggle-sidebar";
window.toggleSidebar = toggleSidebar

import {closeNavbar} from "../components/close-navbar";
closeNavbar()

import {accountCheckBoxAnchor} from "../components/account_check_box_anchor";
accountCheckBoxAnchor()

import {beautifyProfileCheckBoxes} from "../components/beautify-profile-check-boxes";
beautifyProfileCheckBoxes()

import {faqAccordeon} from "../components/faq-accordeon";
faqAccordeon()

import { initCollectPointsForm } from "../components/collect-points-form";
initCollectPointsForm()

import { spendingForm } from "../components/spending-form";
spendingForm()
window.spendingForm = spendingForm

import { welcomeMessagePopup } from "../components/welcome-message-popup";
welcomeMessagePopup()

import { recommendationFormListener } from "../components/recommendation-form.js";
recommendationFormListener()

import { addressAutocomplete } from '../components/address_autocomplete.js';
addressAutocomplete()

import { registerPopup } from "../components/register-popup.js"
registerPopup()

import { setUpAxeptio } from '../components/axeptio.js';
window.setUpAxeptio = setUpAxeptio

const getCookie = (name) => document.cookie.split(";").filter(item => item.trim().startsWith(name))
window.getCookie = getCookie

const cookieExists = (name) => window.getCookie(name).length > 0
window.cookieExists = cookieExists

import "controllers"

Rails.start();
