import { stringify } from "postcss"
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    srcDesktop: String,
    srcMobile: String,
    posterDesktopWebp: String,
    posterDesktopPng: String,
    posterMobileWebp: String,
    posterMobilePng: String
  }

  connect() {
    this.previousFormat = "initialization"
    this.updateSource()
  }

  getCurrentFormat(currentWidth) {
    if (currentWidth < 768 ) return "mobile"

    return "desktop"
  }

  updateSource() {
    const currentFormat = this.getCurrentFormat(window.innerWidth)
    if (currentFormat === this.previousFormat) return;

    const browserSupportWebP = async () => {
      return new Promise(callback => {
        const webP = new Image();
        webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
        webP.onload = webP.onerror = () => {
          callback(webP.height === 2);
        };
      })
    };

    browserSupportWebP().then((webpSupported) => {
      this._setSrc(currentFormat, webpSupported);
    });
  }

  _setSrc(currentFormat, webpSupported){
    const posterDesktop = webpSupported ? this.posterDesktopWebpValue : this.posterDesktopPngValue;
    const posterMobile = webpSupported ? this.posterMobileWebpValue : this.posterMobilePngValue;
    this.previousFormat = currentFormat

    if (currentFormat === "desktop") {
      this.element.setAttribute("poster", posterDesktop)
      this.element.setAttribute("src", this.srcDesktopValue)
    } else {
      this.element.setAttribute("poster", posterMobile)
      this.element.setAttribute("src", this.srcMobileValue)
    }
  }
}
