function initCollectPointsForm() {
  const form = document.querySelector('#collect_points_form')
  if (document.querySelector('.fa-search')) {
    document.querySelector('.fa-search').addEventListener('click', function(e){
      e.currentTarget.classList.add('clicked');
      form.submit()
    })
  }
  if (document.querySelectorAll('.button-group-item').length > 1) {
    const btns = document.querySelectorAll('.button-group-item')
    btns.forEach((btn) => {
      btn.addEventListener('click', function(e){
        btns.forEach((btn) => {btn.classList.remove("active");})
        e.currentTarget.classList.add("active");
        form.submit()
      })
    })
  }
  if (document.querySelector('#collect_point_query')) {
    document.querySelector('#collect_point_query').addEventListener('keyup', function(e){
      e.currentTarget.form.submit()
    })
  }
}

export { initCollectPointsForm };
