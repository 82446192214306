function beautifyProfileCheckBoxes(){
  const checkBoxes = document.querySelectorAll('.user_favorite, .user_interest')
  if(checkBoxes.length > 0){
  checkBoxes.forEach((checkBox) => {
    checkBox.addEventListener('click',(e)=>{
      e.currentTarget.classList.toggle('active');
    })
  })
  }
}

export { beautifyProfileCheckBoxes }
