import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["frame", "background"];

  cancel(event) {
    if (event.target !== this.backgroundTarget) return;

    this.close();
  }

  close() {
    this.frameTarget.classList.add("hidden");
  }
}
