import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"];

  connect() {
    if (this._invalidSetup()) return

    this._setInitial()
  }

  _invalidSetup() {
    return (this._substiText() === '' || this._substiText() === undefined)
  }

  _isInput() {
    return this.element.tagName.toLowerCase() === 'input'
  }

  _setInitial() {
    if (this._isInput()) {
      this.element.dataset.initial = this.element.value
    } else {
      this.element.dataset.initial = this.element.innerText
    }
  }

  substitute() {
    if (this._invalidSetup()) return

    if (this._isInput()) {
      this.element.value = this._substiText()
    } else {
      this.element.innerText = this._substiText()
    }
  }

  revert() {
    if (this._invalidSetup()) return

    if (this._isInput()) {
      this.element.value = this._iniText()
    } else {
      this.element.innerText = this._iniText()
    }
  }

  _substiText() {
    return this.element.dataset.substitext
  }

  _iniText() {
    return this.element.dataset.initial
  }
}
