import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['card']

  close(){
    this.closeModals()
    this.cardTarget.remove()
    this.closeOffersContainer()
  }

  closeModals(){
    document.querySelectorAll('.close').forEach(btn => btn.click())
  }

  closeOffersContainer(){
    if (this.offersCards.length < 1) { this.offersContainer.remove() }
  }

  get offersContainer(){
    return document.querySelector('[data-offer-container]')
  }

  get offersCards(){
    return this.offersContainer.querySelectorAll("[data-offer-card-target='card']")
  }
}
