import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["formGroup", "input", "label"];

  connect() {
    if (this.inputTarget.value !== "" || this.inputTarget.selectedIndex) {
      this.moveLabelOut();
    }

    const feedback = this.formGroupTarget.querySelector(".invalid-feedback");
    if (feedback) feedback.remove();

    this.connected = true;
  }

  moveLabelIn() {
    if (this.inputTarget.value === "" || this.inputTarget.selectedIndex === 0) {
      this.labelTarget.style.top = null;
      this.labelTarget.style.left = null;
    }
  }

  moveLabelOut() {
    this.labelTarget.style.top = "-0.6rem";

    this.labelTarget.style.left = "0.6rem";
    if (this.connected) {
      this.labelTarget.classList.remove("invalid");
      if ("select" in this.inputTarget) this.inputTarget.select();
    }
  }
}
