import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "group" ]

  toggle () {
    if (this.containerTarget.classList.contains("navbar-unfolded")) {
      this.fold()
    }
    else {
      this.unfold()
    }
  }

  fold () {
    this.containerTarget.classList.remove("navbar-unfolded")
    this.foldGroups()
  }

  unfold () {
    this.containerTarget.classList.add("navbar-unfolded")
  }

  unfoldGroup (e) {
    if (e.currentTarget == this.titleOnlyTarget) return

    this.unfold()
    this.foldGroups()
    e.currentTarget.classList.add("navbar-group-unfolded")
  }

  foldGroups () {
    this.groupTargets.forEach(el => {
      el.classList.remove("navbar-group-unfolded")
    })
  }

}
