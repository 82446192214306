const axeptioBtn = () =>  document.querySelector('#axeptio_main_button')

const hideAxeptioBtn = () => {
  if (axeptioBtn()) {
    axeptioBtn().classList.remove('hMxmxV')
    axeptioBtn().style.left = '-50px'
  }
}

const showAxeptioBtn = () => {
  if (axeptioBtn()) {
    axeptioBtn().classList.add('hMxmxV')
  }
}

const setUpAxeptio = () => {
  if (window.axeptioLaunched) return

  window.axeptioLaunched = true
  window.axeptioSettings = {
    clientId: "6166ef351926fb56e3eddfc1",
  };

  const t = document.getElementsByTagName('script')[0]
  const e = document.createElement('script');
  e.async = true;
  e.src = "//static.axept.io/sdk.js";
  t.parentNode.insertBefore(e, t);
}

export { setUpAxeptio }
