const mapboxScript = () => document.querySelector('#search-js')
const addressInput = () => document.querySelector('#collect-point-address-input')
const iconPin      = () => document.querySelector('#collect-point-geoloc')

const initMapboxAutofill = () => {
  if (!mapboxScript()) return

  mapboxScript().onload = function() {
    const autofillCollection = mapboxsearch.autofill({
      accessToken: mapboxScript().dataset.key,
      options: {
        country: ['fr', 'be'],
        language: 'fr'
      }
    })

    autofillCollection.addEventListener('retrieve', (event) => {
      if (event.detail.features.length === 0) return

      addressInput().value = event.detail.features[0].properties.place_name
      addressInput().form.submit()
    });
  };
}

const geolocate = (e) => {
  const iconPin = e.currentTarget
  iconPin.querySelector('#spinner_collect-point-geoloc').style.display = 'block'
  iconPin.querySelector('#svg_collect-point-geoloc').style.display = 'none'

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  function success(pos) {
    const crd = pos.coords
    addressInput().value = `${crd.latitude},${crd.longitude}`
    addressInput().form.submit()
  }

  function error(err) {
    iconPin.querySelector('#spinner_collect-point-geoloc').style.display = 'none'
    iconPin.querySelector('#svg_collect-point-geoloc').style.display = 'block'
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  navigator.geolocation.getCurrentPosition(success, error, options);
}

const addressAutocomplete = () => {
  if (!addressInput()) return

  // if (addressInput().value === '') { geolocate() }
  iconPin()?.addEventListener('click', geolocate)
  initMapboxAutofill()
}

export { addressAutocomplete }
