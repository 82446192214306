import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "input" ]

  up () {
    this.value = this.value + 1
  }

  down () {
    this.value = this.value - 1
  }

  change() {
    const event = new Event('change')
    this.inputTarget.dispatchEvent(event)
  }

  get value () {
    if (this.inputTarget.value) {
      return parseInt(this.inputTarget.value)
    }
    else {
      return 0
    }
  }

  set value (val) {
    this.inputTarget.value = val
    this.change()
  }
}