import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css" // Note this is important!
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {
  replace(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;

    this.element.innerHTML = JSON.parse(xhr.responseText).html
    this._resetFlatpickr()
  }

  _resetFlatpickr() {
    flatpickr(".datepicker", {
      "locale": French
    })
  }
}
