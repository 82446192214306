import { Controller } from "stimulus"

export default class extends Controller {
  update(e) {
    e.preventDefault()
    e.stopPropagation()

    this._toggleParam(e.currentTarget.id, e.currentTarget.dataset.type)
    this._applyFilter()
  }

  reset() {
    const params = new URLSearchParams(window.location.search)
    params.delete("categories[]")

    if (params.values().next()) {
      this.url = window.location.origin + window.location.pathname + '?' + params.toString()
    } else {
      this.url = window.location.origin + window.location.pathname
    }

    this._applyFilter()
  }

  // Private Methods

  _applyFilter() {
    window.history.pushState('', '', this.url)
    fetch(this.url, {
      headers: {
        Accept: 'application/json'
      }
    }).then(res => res.json())
      .then(data => {
        this.element.innerHTML = data.products
      })
  }

  _toggleParam(category, paramsType) {
    const params = new URLSearchParams(window.location.search)
    if (params.getAll(paramsType + '[]').includes(category)) {
      this._removeParam(category, params, paramsType)
    } else {
      this._addParam(category, params, paramsType)
    }
  }

  _addParam(category, params, paramsType) {
    if (params.getAll(paramsType + '[]').includes(category)) return;

    params.append(paramsType + '[]', category)
    this.url = window.location.origin + window.location.pathname + '?' + params.toString()
  }

  _removeParam(category, params, paramsType) {
    const allValues = params.getAll(paramsType + '[]')
    if (!allValues.includes(category)) return;

    if (allValues.length < 1) {
      this.url = window.location.origin + window.location.pathname
    } else {
      const regexp = new RegExp(`&?${paramsType}(%5B|\\[)(%5D|\\])=${category}`)
      this.url = window.location.href.replace(regexp, "")
    }
  }
}
