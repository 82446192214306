import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['drawer', 'drawerItem']

  toggle() {
    this.drawerTarget.dataset.state == 'expanded' ? this.collapse() : this.expand()
  }

  expand() {
    this.drawerTarget.dataset.state = 'expanded'
    this.drawerTarget.style.transform = 'translate(0, 46px)'
    this.drawerItemTargets.forEach((item) => item.classList.add('py-2'))
  }

  collapse() {
    this.drawerTarget.dataset.state = 'collapsed'
    this.drawerTarget.style.transform = 'translate(0, 0)'
    this.drawerItemTargets.forEach((item) => item.classList.remove('py-2'))
  }
}
