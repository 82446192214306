import { Controller } from "stimulus"
import fuzzy from "fuzzy"

export default class extends Controller {
  static targets = ["input", "brand"]

  search () {
    let brands = this.brandTargets.map(el => el.dataset.name)
    let result = fuzzy.filter(this.query, brands).map(r => r.string)

    this.brandTargets.forEach(el => {
      if (result.includes(el.dataset.name)) {
        el.classList.remove("hidden")
      }
      else {
        el.classList.add("hidden")
      }
    })
  }


  get query () {
    return this.inputTarget.value
  }

}