import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["collectPoint"]

  filter(e){
    const query = e.currentTarget.value.toLowerCase()
    this.collectPointTargets.forEach(cp =>
      cp.classList.toggle(
        "hidden",
        !cp.querySelector('address').innerText.toLowerCase().includes(query)
      )
    )
  }
}
